import React from "react";
import { Form, Row, Col, Typography } from "antd";
import style from "./login.module.scss";
import { useCustomLogin } from "../../hooks/useCustomLogin/UseCustomLogin";
import MicrosoftLogin from "../../components/organisms/microSoftLogin/MicrosoftLogin";
const { Text } = Typography;
export const Login: React.FC = () => {
  const { onFinish, onFinishFailed } = useCustomLogin()

  return (
    <Row justify="center" align="middle" className={style.loginContainer}>
      <Col xs={24} sm={16} md={12} lg={10} xl={8}>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={style.loginForm}
        >
          <div style={{ marginBottom: "1rem", textAlign: "center" }}>
            <Text style={{ fontWeight: 500, fontSize: "1.5rem" }}>
              Admin Login
            </Text>
          </div>
          <div>
            <MicrosoftLogin />
          </div>
        </Form>
      </Col>
    </Row>
  );
};

///////////////////////////////////////////////////--........................//////////
// import React from "react";
// import { Form, Input, Button, Row, Col } from "antd";
// import { UserOutlined, LockOutlined } from "@ant-design/icons";
// import style from "./login.module.scss";
// import { Typography } from "antd";

// import { useCustomLogin } from "../../hooks/useCustomLogin/UseCustomLogin";
// import MicrosoftLogin from "../../components/organisms/microSoftLogin/MicrosoftLogin";
// const { Text } = Typography;
// export const Login: React.FC = () => {
//   const {onFinish,onFinishFailed}=useCustomLogin();

//   return (
//     <Row justify="center" align="middle" className={style.loginContainer}>
//       <Col xs={24} sm={16} md={12} lg={10} xl={8}>
//         <Form
//           name="login"
//           initialValues={{ remember: true }}
//           onFinish={onFinish}
//           onFinishFailed={onFinishFailed}
//           className={style.loginForm}
//         >
//           <div style={{ marginBottom: "1rem" }}>
//             <Text style={{ fontWeight: 500, fontSize: "1.5rem" }}>
//               Admin Login
//             </Text>
//           </div>
//           <Form.Item
//             name="email"
//             rules={[
//               { required: true, message: "Please input your email!" },
//               { type: "email", message: "The input is not valid E-mail!" },
//             ]}
//           >
//             <Input
//               prefix={<UserOutlined className="site-form-item-icon" />}
//               placeholder="Email"
//             />
//           </Form.Item>

//           <Form.Item
//             name="password"
//             rules={[{ required: true, message: "Please input your Password!" }]}
//           >
//             <Input
//               prefix={<LockOutlined className="site-form-item-icon" />}
//               type="password"
//               placeholder="Password"
//             />
//           </Form.Item>
//           <Form.Item>
//             <a className={style.forgotPassword} href="/forgot-password">
//               Forgot password?
//             </a>
//           </Form.Item>
//           <Form.Item>
//             <Button
//               type="primary"
//               htmlType="submit"
//               className={style.loginButton}
//             >
//               Log in
//             </Button>
//           </Form.Item>
//           <div>
//           <MicrosoftLogin/>
//         </div>
//         </Form>
       
//       </Col>
//     </Row>
//   );
// };